// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-procedures-js": () => import("./../../../src/pages/procedures.js" /* webpackChunkName: "component---src-pages-procedures-js" */),
  "component---src-pages-trainings-js": () => import("./../../../src/pages/trainings.js" /* webpackChunkName: "component---src-pages-trainings-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-procedure-js": () => import("./../../../src/templates/procedure.js" /* webpackChunkName: "component---src-templates-procedure-js" */),
  "component---src-templates-specialist-js": () => import("./../../../src/templates/specialist.js" /* webpackChunkName: "component---src-templates-specialist-js" */)
}

