module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WZ7C9VD","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"gtm.start":1686240534694,"event":"gtm.js"}},"dataLayerName":"dataLayer","routeChangeEventName":"ROUTE_CHANGE","enableWebVitalsTracking":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 320px)","sm":"(max-width: 768px)","md":"(max-width: 1024px)","l":"(max-width: 1367px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Premium Studio","short_name":"Premium Studio","start_url":"/","background_color":"#FFF","theme_color":"#000","display":"standalone","icon":"content/images/logo.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"53d954f8eb91e1504ef859baa578b470"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Premium Studio - Leader operacji plastycznych w Turcji","description":"Potrzebujesz frakcji mikroigłowej, zabiegu bezinwazyjnego liftingu twarzy hifu, a może makijażu permanentnego (microblading)? Zapraszamy do nas!","language":"pl-PL","openGraph":{"type":"website","locale":"pl_PL","url":"https://premiumstudio.co","site_name":"Premium Studio","title":"Premium Studio - Leader operacji plastycznych w Turcji","description":"Potrzebujesz frakcji mikroigłowej, zabiegu bezinwazyjnego liftingu twarzy hifu, a może makijażu permanentnego (microblading)? Zapraszamy do nas!"},"twitter":{"cardType":"summary_large_image"},"facebook":{"appId":"PremiumStudioklinika"},"metaTags":[{"name":"twitter:title","content":"Premium Studio - Leader operacji plastycznych w Turcji"},{"name":"twitter:description","content":"Potrzebujesz frakcji mikroigłowej, zabiegu bezinwazyjnego liftingu twarzy hifu, a może makijażu permanentnego (microblading)? Zapraszamy do nas!"},{"name":"facebook-domain-verification","content":"l8rnwd0tuz0v7rrmxnovsbjgdrzu52"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
